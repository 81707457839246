import { Link, InertiaLinkProps } from "@inertiajs/react";

export default function NavLink({
    active = false,
    className = "",
    children,
    ...props
}: InertiaLinkProps & { active?: boolean }) {
    return (
        <Link
            {...props}
            className={
                "nav-item data-[state=active]:bg-theme-100 p-3 data-[state=active]:py-3 data-[state=active]:rounded-3xl data-[state=active]:text-prim-900 " +
                className
            }
        >
            {children}
        </Link>
    );
}
